'use client';

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import Image from 'next/image';

import type { IFAQ } from '../home.interface';

interface IProps {
  faq: IFAQ;
  _tag: string;
}

export const FAQ = ({ faq, _tag }: IProps) => {
  const { question, tags, answer } = faq;

  return (
    <div className="w-full">
      {tags.includes(_tag) ? (
        <div className="mx-auto mb-3 w-full rounded-xl border-[1px] border-[#E6E6E6] bg-white p-2 opacity-75 hover:bg-[#f7fcff] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 md:mb-6 md:w-[85%]  md:px-6 md:py-4">
          <Disclosure>
            {({ open }) => (
              <>
                <DisclosureButton
                  className={
                    'flex w-full justify-between px-4 py-2.5 text-left '
                  }
                >
                  <span className="w-[77%] text-[17px] font-medium text-[#555659] md:w-[100%] md:text-[18px]">
                    {question}
                  </span>
                  <div className="h-[32px] w-[32px] shrink-0 grow-0">
                    <Image
                      src="/images/icons/faq/list-down-icon.png"
                      className={`mt-1 rounded-full bg-[#F2FAFF] text-[#67B5E5] ${
                        open ? 'rotate-180' : ''
                      }`}
                      alt="Arrow down"
                      width={32}
                      height={32}
                    />
                  </div>
                </DisclosureButton>
                <DisclosurePanel className="px-4 pb-4 pt-2 text-sm text-gray-500 ">
                  <div
                    className="w-[77%] text-left text-[15px] font-normal text-[#616366] md:w-[95%] md:text-[18px]"
                    dangerouslySetInnerHTML={{ __html: answer }}
                  />
                </DisclosurePanel>
              </>
            )}
          </Disclosure>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
