import amplitude from 'amplitude-js';

import { amplitudeId } from './processenv';

let IsInit = false;
export const initAmplitude = (callaback?: () => any) => {
  if (amplitudeId) {
    amplitude.getInstance().init(amplitudeId);
    IsInit = true;
    if (callaback) {
      callaback();
    }
  }
};

export const setAmplitudeUserDevice = (installationToken: string) => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties: any) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType: string, eventProperties?: any) => {
  if (!IsInit) {
    initAmplitude(() => sendAmplitudeData(eventType, eventProperties));
    return;
  }
  amplitude.getInstance().logEvent(eventType, eventProperties);
};

export const getAmplitudeDeviceId = () => {
  return amplitude.getInstance().getDeviceId();
};
