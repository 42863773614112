import { addParameter } from './addParameter';
import { getAmplitudeDeviceId } from './amplitude';
import { dashboardUrl } from './processenv';

export const GetDashboardUrlWithAnalytics = (path: string, params = {}) => {
  let allParams = { ...params };
  try {
    const amplitudeDeviceId = getAmplitudeDeviceId();
    allParams = { ...allParams, amplitudeDeviceId };
  } catch (e) {
    // console.error(e);
  }

  let paramsString = '';
  Object.entries(allParams).forEach(([key, value]) => {
    paramsString = addParameter(paramsString, key, value as string).toString();
  });

  return `${dashboardUrl}/${path}${paramsString}`;
};
