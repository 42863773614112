'use client';

import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { faqs } from '@/src/constants/FAQs';

import type { IFAQ, IFAQTags } from '../home.interface';
import { FAQ } from './FAQ';

export const FAQs = ({ tag }: IFAQTags) => {
  const router = useRouter();
  return (
    <div className="container mx-auto px-6 py-16 text-center md:px-16 2xl:px-24">
      <div className="py-8 text-center text-[28px] font-medium text-[#3D3E40] md:text-4xl">
        Frequently Asked Questions
      </div>
      <div className="md:mt-6">
        {faqs.map((faq: IFAQ, index: number) => (
          <FAQ key={index} faq={faq} _tag={tag} />
        ))}
      </div>

      <div className="mt-8 flex flex-col items-center justify-center gap-y-1 md:flex-row md:gap-x-2 ">
        <button
          className="flex cursor-pointer items-center gap-x-1 self-center rounded-lg border-2 border-solid border-[#318AE5] p-4 text-[16px] font-medium text-[#318AE5] hover:bg-[#318AE5] hover:text-white"
          onClick={() => router.push('/faqs')}
        >
          <span>View all FAQs</span> <ChevronRightIcon className="h-5 w-5" />
        </button>
        <Link
          href="/contact-us"
          className="cursor-pointer px-6 text-[16px] font-medium text-[#318AE5] hover:text-[#2B81D9]"
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
};
