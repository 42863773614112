/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable tailwindcss/no-custom-classname */

'use client';

import 'swiper/css';
import 'swiper/css/pagination';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import React from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { reviews } from '@/src/constants/Reviews';

import type { IReview } from '../home.interface';

const ReviewCard = ({ name, text, occupation }: IReview) => {
  return (
    <div className="m-6 flex h-[100%] overflow-auto rounded-2xl bg-[#F7FCFF] bg-origin-padding px-6 py-7 md:m-0 md:max-w-lg">
      <div className="relative z-10">
        <div className="absolute left-0 top-0 w-8">
          <Image
            src="/images/icons/ic_inverted_comma.svg"
            alt=""
            width={32}
            height={32}
          />
        </div>
        <div className="absolute left-[2rem] top-0 w-8">
          <Image
            src="/images/icons/ic_inverted_comma.svg"
            alt=""
            width={32}
            height={32}
          />
        </div>
      </div>
      <div className="flex h-full flex-col justify-center">
        <p className="z-20 ml-3 pt-2 text-left text-[17px] font-normal text-[#555659]">
          {text}
        </p>
        <div className="z-20 ml-3 mt-2 text-left text-base font-bold text-[#3D3E40]">
          {name}
        </div>
        <div className="z-20 ml-3 text-left text-base text-[#3D3E40]">
          {occupation}
        </div>
      </div>
    </div>
  );
};

const NavigationView = () => {
  return (
    <div className="mt-6 flex w-full items-center justify-center gap-x-1 md:mt-12">
      <button
        aria-label="backward"
        className="swiper-back mr-4 mt-1 h-5 w-5 rounded-full border-2 border-[#8A8F99]"
      >
        <ChevronLeftIcon className="text-[#8A8F99]" />
      </button>
      <div className="swiper-pagination !static !z-0 mt-1 flex !w-auto min-w-[5rem] !shrink-0 flex-nowrap" />
      <button
        aria-label="forward"
        className="swiper-forward ml-4 mt-1 h-5 w-5 rounded-full border-2 border-[#8A8F99] xl:ml-0"
      >
        <ChevronRightIcon className="text-[#8A8F99]" />
      </button>
    </div>
  );
};

export const Reviews = () => {
  return (
    <div className="container relative mx-auto mt-20 px-4 md:px-10 lg:px-16">
      <div className="text-center text-[28px] font-medium text-[#3D3E40] md:text-[36px]">
        Loved by Special Ed Professionals
      </div>
      <div className="text-center text-[15px] font-normal text-[#555659] md:text-[18px]">
        IEP teams trust us for data collection. <br className="md:hidden" />{' '}
        Here&apos;s what they have to say
      </div>
      <Swiper
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        spaceBetween={10}
        navigation={{
          nextEl: '.swiper-forward',
          prevEl: '.swiper-back',
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
        }}
        breakpoints={{
          700: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 60,
          },
        }}
        className="mt-8 flex h-[22rem] w-full flex-nowrap md:mt-16 md:h-[15rem]"
      >
        {reviews.map((review: IReview, index: number) => {
          if (review.type?.includes('carousel'))
            return (
              <SwiperSlide key={index}>
                <ReviewCard
                  name={review.name}
                  text={review.text}
                  occupation={review.occupation}
                />
              </SwiperSlide>
            );
          return <span key={index} />;
        })}
      </Swiper>
      <NavigationView />
    </div>
  );
};
