import Airtable from 'airtable';

import type { IIntegration } from '@/src/interfaces';

import { airtableApiKey } from './processenv';

Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: airtableApiKey,
});

export const createIntegration = async (data: IIntegration) => {
  const base = Airtable.base('appSk9sOD0Sc4bKfX');
  const result = await base('tblv7XLeafI67Eviz').create([
    {
      fields: {
        fld9UOBW03Z16eUcW: data.link,
        fldlRPNR9FcExCtYk: data.username,
        fldJgwqkaWDQGnyV3: data.password,
        fld6jrMokmeybvOiK: data.IEPSystem,
      },
    },
  ]);
  return result;
};
