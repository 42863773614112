export const faqs = [
  {
    question: 'Where can I learn how to use AbleSpace?',
    tags: ['home', 'all'],
    answer:
      'We have a library of tutorial videos that can help you understand the platform. You can access them <a href="https://www.ablespace.io/tutorials" class="text-[#318AE5] font-bold">here</a>. Meanwhile, if you would like a one-on-one demo, please message us via chat or email us at <a href="mailto:support@ablespace.io" class="text-[#318AE5] font-bold">support@ablespace.io</a>.',
  },
  {
    question: 'Can I share data with my paraprofessionals and assistants?',
    tags: ['home', 'all'],
    answer:
      'Certainly! You can facilitate secure data sharing with paras and assistants, enabling seamless data collection and progress monitoring on the students.',
  },
  {
    question: 'Can you track data for multiple students simultaneously?',
    tags: ['home', 'all'],
    answer:
      'Absolutely! Our intuitive UI enables you to effortlessly gather data for group sessions as well. Progress monitoring for a group has never been more seamless and straightforward!',
  },
  {
    question:
      'Is AbleSpace HIPAA compliant? Will you sign a Business Associate Agreement (BAA)?',
    tags: ['home', 'all'],
    answer:
      'Yes, AbleSpace is HIPAA compliant. We have a standard <a href="https://ablespace.io/business-associate-agreement" class="text-[#318AE5] font-bold">Business Associate Agreement (BAA)</a> applicable as soon as you sign up and accept the terms of service. We can also provide individual BAA in case you would need one. Feel free to contact us at <a href="mailto:support@ablespace.io" class="text-[#318AE5] font-bold">support@ablespace.io</a>.',
  },
  {
    question: 'I have suggestions to improve AbleSpace; whom should I contact?',
    tags: ['all'],
    answer:
      'We are always looking for suggestions to improve the platform. You can contact us via the live chat on this page or write to us at <a href="mailto:support@ablespace.io" class="text-[#318AE5] font-bold">support@ablespace.io</a>.',
  },
  {
    question: 'What happens after my free trial ends?',
    tags: ['pricing', 'all'],
    answer:
      "If you haven't opted for a subscription by providing your payment details, your account will transition to the free basic plan after the free trial period. You have the option to upgrade your plan at any time in the future if you desire.",
  },
  {
    question: 'Can you collect data on the phone and access it on a laptop?',
    tags: ['all'],
    answer:
      'Yes, you can collect data on any device, and it becomes available on all other devices.',
  },
  {
    question:
      'Can I create my own assessments on AbleSpace, or only use the prebuilt ones?',
    tags: ['all'],
    answer: 'You can also create custom assessments with AbleSpace. ',
  },
  {
    question: 'Do you offer special pricing for schools and districts?',
    tags: ['pricing', 'all'],
    answer:
      'Yes, bulk purchase discounts are available for schools and districts. Don’t hesitate to contact us at support@ablespace.io, and we can share more details.',
  },
  {
    question: 'Do I need to enter my credit card to start the trial?',
    tags: ['pricing', 'all'],
    answer: 'There is no credit card needed to start the trial.',
  },
  {
    question:
      'Is there a limit to the number of students I can add to my account?',
    tags: ['pricing', 'all'],
    answer: 'You can add an unlimited number of students to your account.',
  },
  {
    question:
      'Is there a limit to the number of paraprofessionals/assistants I can add to my account?',
    tags: ['pricing', 'all'],
    answer: 'You can add unlimited paras and assistants to your account.',
  },
  {
    question:
      'Can I upload attachments such as photos of student work when creating session notes?',
    tags: ['all'],
    answer:
      'Yes, you can upload attachments such as images, pdf, ppt, etc, as part of a student note.',
  },
  {
    question: 'Can I cancel my subscription?',
    tags: ['all'],
    answer: 'Yes, you can cancel your subscription anytime.',
  },
  {
    question: 'What if I need help using AbleSpace? Can I have a demo?',
    tags: ['all'],
    answer:
      'Yes, we would be happy to schedule a 1-to-1 demo to explain how AbleSpace works and answer any questions you might have. Please write to us at <a href="mailto:support@ablespace.io" class="text-[#318AE5] font-bold">support@ablespace.io</a>, and we will coordinate to find a convenient time for the demo.',
  },
  {
    question: 'Can I purchase a plan with my ESSER, EANS, or Title I funds?',
    tags: ['schools/districts', 'all'],
    answer:
      'Yes, AbleSpace qualifies for federal funding including ESSER, EANS, and Title I. AbleSpace can support key initiatives such as data tracking, assessments, reporting and health and safety insights.',
  },
  {
    question:
      'Is AbleSpace compliant with HIPAA, FERPA, and state privacy regulations?',
    tags: ['schools/districts', 'all'],
    answer:
      'Yes, AbleSpace complies with HIPAA, FERPA, and state privacy regulations regarding student data. We follow all applicable laws and regulations to ensure the privacy and security of student data.',
  },
  {
    question: 'What if my teachers already paid for their own plans?',
    tags: ['schools/districts', 'all'],
    answer:
      "We'll fully reimburse teachers at your school or district who paid out of pocket to upgrade to AbleSpace Plus as long as they upgraded during the current academic year.",
  },
  {
    question: 'Do you offer training on using AbleSpace?',
    tags: ['schools/districts', 'all'],
    answer:
      'Yes, we offer detailed training sessions for both staff and admins to ensure that everyone is comfortable and confident using AbleSpace. We also offer ongoing support and resources to help ensure that you continue to get the most out of the app. Whether you have a question about a specific feature or need assistance with a technical issue, our support team is available to help.',
  },
  {
    question: 'What student information does AbleSpace collect?',
    tags: ['Hipaa/Ferpa', 'all'],
    answer:
      'AbleSpace collects student information such as name, date of birth, and school to assist special ed professionals in their workflows. More details can be found on <a href="https://www.ablespace.io/student-data-fields/" class="text-[#318AE5] font-bold">this page.</a>',
  },
  {
    question: ' How is personal information stored and protected in AbleSpace?',
    tags: ['Hipaa/Ferpa', 'all'],
    answer:
      'All personal information is stored in a secure database with restricted access. We take appropriate measures to protect personal information against unauthorized access, disclosure, alteration, and destruction.',
  },
  {
    question: 'Does AbleSpace share personal information with third parties?',
    tags: ['Hipaa/Ferpa', 'all'],
    answer:
      'We do not share personal information with third parties except as required by law or to provide services to our users. We also do not sell personal information.',
  },
  {
    question:
      'Is AbleSpace compliant with HIPAA, FERPA, and state privacy regulations?',
    tags: ['Hipaa/Ferpa', 'all'],
    answer:
      'Yes, AbleSpace complies with HIPAA, FERPA, and state privacy regulations regarding student data. We follow all applicable laws and regulations to ensure the privacy and security of student data.',
  },
  {
    question:
      ' Can users access, modify, or delete their personal information in AbleSpace',
    tags: ['Hipaa/Ferpa', 'all'],
    answer:
      'Yes, users can access, modify, or delete their personal information anytime by logging into their account and making changes. Users can also request that we delete their account and all associated personal information.',
  },
];
