// existing is 'existing params string'
// adds query param to existing
// returns (existing + new param)

// export const addParameter = (existing: string, key: string, value: string) => {
//   let local = existing;
//   if (typeof value !== 'string' || value.length === 0) {
//     return existing;
//   }
//   if (local === '') {
//     local = '?';
//   }
//   local = `${local}${local.length > 1 ? '&' : ''}${key}=${value}`;
//   return local;
// };

// get existing url
// remove email param
// add params - email, amplitude
export const addParameter = (url: string, key: string, value: string) => {
  if (typeof value !== 'string' || value.length === 0) {
    return url;
  }
  const u = new URL(url); // new URL("http://foo.bar/?x=1&y=2")
  u.searchParams.set(key, value); // result is "http://foo.bar/?x=42&y=2"
  return u;
};
